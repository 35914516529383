
@media print{


    body{
        margin: 0.8cm 1.3cm 0.8cm 1.3cm;
        font-size:12px !important;
        line-height:17px !important;
    }

    ul{
        padding-left:20px !important;
    }


    ui.table{
        font-size:9px !important;
    }
}
